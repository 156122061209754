// hooks/useFirebaseData.js
import { useState, useEffect } from 'react';
import { collection, query, orderBy, limit, where, onSnapshot, getDocs, addDoc } from 'firebase/firestore';
import { db } from '../firebase';

const TOKEN_PUBLIC_KEY = '5Kj1UBryo1fBFXMLxbq5jESN7fX9DUG7sGSvzPrVpump';
const QUICKNODE_RPC = 'https://quaint-silent-sun.solana-mainnet.quiknode.pro/583c48688406d493ad202959bf0a3d7e9965fdf3';
const MIN_TOKEN_AMOUNT = 0.01;
const REFRESH_INTERVAL = 30000;

// Función para generar datos mock
const generateMockData = () => ({
  transactions: Array.from({ length: 30 }, (_, i) => ({
    id: `recent-${i}`,
    txType: Math.random() > 0.5 ? 'buy' : 'sell',
    tokenAmount: (Math.random() * 1 + 1).toFixed(2),
    traderPublicKey: `Trader${(Math.random() * 1000000).toString(16)}`,
    signature: `sig${(Math.random() * 1000000).toString(16)}`,
    timestamp: Date.now() - Math.random() * 3600000
  })).sort((a, b) => b.timestamp - a.timestamp),

  topBuys: Array.from({ length: 20 }, (_, i) => ({
    id: `top-${i}`,
    txType: 'buy',
    tokenAmount: (Math.random() * 1 + 3).toFixed(2),
    traderPublicKey: `Trader${(Math.random() * 1000000).toString(16)}`,
    signature: `sig${(Math.random() * 1000000).toString(16)}`,
    timestamp: Date.now() - Math.random() * 86400000
  })).sort((a, b) => b.tokenAmount - a.tokenAmount),

  marketInfo: {
    marketCapSol: (Math.random() * 1000000 + 500000).toFixed(2),
    vSolInBondingCurve: (Math.random() * 100000 + 50000).toFixed(2),
    vTokensInBondingCurve: (Math.random() * 1000000 + 500000).toFixed(2)
  }
});

const USE_MOCK_DATA = true;

export const useFirebaseData = () => {
  const [transactions, setTransactions] = useState([]);
  const [topBuys, setTopBuys] = useState([]);
  const [marketInfo, setMarketInfo] = useState({
    marketCapSol: 0,
    vSolInBondingCurve: 0,
    vTokensInBondingCurve: 0
  });

  // Efecto para obtener datos del token y guardarlos en Firebase
  useEffect(() => {
    if (USE_MOCK_DATA) return;

    const fetchTokenData = async () => {
      try {
        const response = await fetch(QUICKNODE_RPC, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            jsonrpc: '2.0',
            id: 1,
            method: 'getSignaturesForAddress',
            params: [
              TOKEN_PUBLIC_KEY,
              { limit: 50, commitment: 'confirmed' }
            ]
          })
        });

        const signaturesData = await response.json();
        if (signaturesData.error) throw new Error(signaturesData.error.message);

        const transactionPromises = signaturesData.result.map(async (signatureInfo) => {
          const txResponse = await fetch(QUICKNODE_RPC, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              jsonrpc: '2.0',
              id: 1,
              method: 'getTransaction',
              params: [
                signatureInfo.signature,
                { encoding: 'json', maxSupportedTransactionVersion: 0 }
              ]
            })
          });

          const txData = await txResponse.json();
          if (txData.error || !txData.result) return null;

          const tx = txData.result;
          let txType = 'unknown';
          let tokenAmount = 0;

          if (tx.meta && tx.meta.preTokenBalances && tx.meta.postTokenBalances) {
            const tokenBalanceChange = tx.meta.postTokenBalances.reduce((acc, post) => {
              const pre = tx.meta.preTokenBalances.find(p => p.accountIndex === post.accountIndex);
              if (pre && pre.uiTokenAmount && post.uiTokenAmount) {
                return acc + (post.uiTokenAmount.uiAmount - pre.uiTokenAmount.uiAmount);
              }
              return acc;
            }, 0);

            tokenAmount = Math.abs(tokenBalanceChange);
            if (tokenAmount >= MIN_TOKEN_AMOUNT) {
              txType = tokenBalanceChange > 0 ? 'buy' : 'sell';
            } else {
              return null;
            }
          }

          const transaction = {
            signature: signatureInfo.signature,
            timestamp: tx.blockTime * 1000,
            txType,
            tokenAmount,
            traderPublicKey: tx.transaction.message.accountKeys[0].toString()
          };

          // Guardar en Firebase
          try {
            await addDoc(collection(db, 'transactions'), transaction);
          } catch (error) {
            console.error('Error saving to Firebase:', error);
          }

          return transaction;
        });

        const txResults = await Promise.all(transactionPromises);
        const validTransactions = txResults.filter(tx => tx !== null);

        // Actualizar market info si hay transacciones válidas
        if (validTransactions.length > 0) {
          const marketUpdate = {
            marketCapSol: validTransactions.reduce((acc, tx) => acc + tx.tokenAmount, 0),
            timestamp: Date.now()
          };

          try {
            await addDoc(collection(db, 'marketInfo'), marketUpdate);
          } catch (error) {
            console.error('Error saving market info:', error);
          }
        }
      } catch (err) {
        console.error('Error fetching token data:', err);
      }
    };

    // Ejecutar inicialmente
    fetchTokenData();

    // Configurar intervalo
    const interval = setInterval(fetchTokenData, REFRESH_INTERVAL);
    return () => clearInterval(interval);
  }, []);

  // Efecto para cargar y escuchar datos de Firebase
  useEffect(() => {
    if (USE_MOCK_DATA) {
      const mockData = generateMockData();
      setTransactions(mockData.transactions);
      setTopBuys(mockData.topBuys);
      setMarketInfo(mockData.marketInfo);

      const interval = setInterval(() => {
        const newMockData = generateMockData();
        setTransactions(newMockData.transactions);
        setTopBuys(newMockData.topBuys);
        setMarketInfo(newMockData.marketInfo);
      }, REFRESH_INTERVAL);

      return () => clearInterval(interval);
    }

    // Cargar datos históricos
    const loadHistoricalData = async () => {
      try {
        const txSnapshot = await getDocs(
          query(collection(db, 'transactions'), orderBy('timestamp', 'desc'), limit(30))
        );
        setTransactions(txSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));

        const topBuysSnapshot = await getDocs(
          query(
            collection(db, 'transactions'),
            where('txType', '==', 'buy'),
            orderBy('tokenAmount', 'desc'),
            limit(20)
          )
        );
        setTopBuys(topBuysSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));

        const marketInfoSnapshot = await getDocs(
          query(collection(db, 'marketInfo'), orderBy('timestamp', 'desc'), limit(1))
        );
        if (!marketInfoSnapshot.empty) {
          setMarketInfo(marketInfoSnapshot.docs[0].data());
        }
      } catch (error) {
        console.error('Error loading historical data:', error);
      }
    };

    loadHistoricalData();

    // Configurar listeners
    const unsubscribeTransactions = onSnapshot(
      query(collection(db, 'transactions'), orderBy('timestamp', 'desc'), limit(30)),
      (snapshot) => setTransactions(snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })))
    );

    const unsubscribeTopBuys = onSnapshot(
      query(
        collection(db, 'transactions'),
        where('txType', '==', 'buy'),
        orderBy('tokenAmount', 'desc'),
        limit(20)
      ),
      (snapshot) => setTopBuys(snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })))
    );

    const unsubscribeMarketInfo = onSnapshot(
      query(collection(db, 'marketInfo'), orderBy('timestamp', 'desc'), limit(1)),
      (snapshot) => {
        if (!snapshot.empty) {
          setMarketInfo(snapshot.docs[0].data());
        }
      }
    );

    return () => {
      unsubscribeTransactions();
      unsubscribeTopBuys();
      unsubscribeMarketInfo();
    };
  }, []);

  return { transactions, topBuys, marketInfo };
};